import React, { useState, useEffect } from 'react';
import './popup.css';

const Popup = ({ children, maxWidth = '500px', isOpen = false, onClose = () => { } }) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    console.log('close')
    console.log(document.getElementsByClassName('popup-content'))
    const element = document.getElementsByClassName('popup-content')
    element[0].addEventListener('animationend', () => {
      console.log('end animation')
      // element[0].classList.toggle('close')
      setIsVisible(false);
      onClose();

    }, { once: true });
    element[0].classList.add('close')
    // onClose();
  };

  return (
    <>
      {isVisible && (
        <div className="popup">
          <div className="popup-content" style={{ maxWidth: maxWidth }}>
            <button className="close-btn" onClick={handleClose}>
              <span className="cross-icon">&#10005;</span>
            </button>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;