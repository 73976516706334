import React, {useEffect, useRef, useState} from 'react';
import FAQSection from "../../components/faqsection/FAQSection";
import Highlight from "../../components/highlight/Highlight";
import axios from "axios";
import {Helmet} from "react-helmet";
import Marquee from "../../components/marquee/Marquee";
import AnimatedNumberComponent from "../../components/сounterсomponent/AnimatedNumberComponent";
import {ReactComponent as ArrowSvg} from '../../assets/images/Стрелка.svg';
import EmblaCarousel from "../../components/Embla Carousel/EmblaCarousel";
import Popup from "../../components/popup/popup";
import {ArrowRight} from "lucide-react";
import '../../components/highlight/HighLight.css';

const HomePage = () => {

  const benefits = [{
    title: 'Уникальные программы \u{1F60E}',
    description: 'Кобрендинговые образовательные программы магистратуры с лидерами IT-отрасли: новейшие сквозные технологии, преподаватели-практики, проектная и исследовательская работа, междисциплинарные учебные модули и проекты.'
  }, {
    title: 'Актуальное обучение \u{1F525}',
    description: 'Тесная связь с индустрией, подготовка специалистов в соответствии с актуальными требованиями работодателей, обучение на реальных задачах.'
  }, {
    title: 'IT-мероприятия \u{1F680}',
    description: 'Современные технологии обучения, дополнительные образовательные программы и мастер-классы от ведущих специалистов IT и бизнес отрасли, хакатоны, акселерационные программы и поддержка студенческих стартапов.'
  }, {
    title: 'Поддержка \u{1F91D}',
    description: 'Комьюнити выпускников и партнеров: наставничество и менторство, нетворкинг и участие в жизни университета.'
  },];

  const highlightsBachelor = [
    {
      title: '01.03.00 Компьютерные науки и прикладная математика',
      description: '<p class="p_description"><span class="d_description">Кол-во бюджетных мест: </span><span class="v_description">225</span></p>' +
          '<p class="p_description"><span class="d_description">Кол-во платных мест: </span><span class="v_description">20+</span></p>' +
          '<p class="p_description"><span class="d_description">Проходной балл: </span><span class="v_description">253</span></p>' +
          '<p class="p_description"><span class="d_description">Экзамены: </span><span class="dv_description">Русский язык, Математика, Информатика или Физика</span></p>' +
          '<p class="p_description"><span class="d_description">Форма обучения: </span><span class="dv_description">Очная</span></p>',
      link: 'https://priem.mai.ru/bachelor/programs/?mega=%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5+%D0%BD%D0%B0%D1%83%D0%BA%D0%B8+%D0%B8+%D0%BF%D1%80%D0%B8%D0%BA%D0%BB%D0%B0%D0%B4%D0%BD%D0%B0%D1%8F+%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0&city=%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0#:~:text=%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5%20(4%20%D0%B3%D0%BE%D0%B4%D0%B0)-,%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5%20%D0%BD%D0%B0%D1%83%D0%BA%D0%B8%20%D0%B8%20%D0%BF%D1%80%D0%B8%D0%BA%D0%BB%D0%B0%D0%B4%D0%BD%D0%B0%D1%8F%20%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0,-01.03.00',
    },
    {
      title: '02.03.02 Фундаментальная информатика и информационные технологии',
      description: '<p class="p_description"><span class="d_description">Кол-во бюджетных мест: </span><span class="v_description">125</span></p>' +
          '<p class="p_description"><span class="d_description">Кол-во платных мест: </span><span class="v_description">20+</span></p>' +
          '<p class="p_description"><span class="d_description">Проходной балл: </span><span class="v_description">257</span></p>' +
          '<p class="p_description"><span class="d_description">Экзамены: </span><span class="dv_description">Русский язык, Математика, Информатика или Физика</span></p>' +
          '<p class="p_description"><span class="d_description">Форма обучения: </span><span class="dv_description">Очная</span></p>',
      link: 'https://priem.mai.ru/bachelor/programs/?mega=%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5+%D0%BD%D0%B0%D1%83%D0%BA%D0%B8+%D0%B8+%D0%BF%D1%80%D0%B8%D0%BA%D0%BB%D0%B0%D0%B4%D0%BD%D0%B0%D1%8F+%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0&city=%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0#:~:text=%D0%A4%D1%83%D0%BD%D0%B4%D0%B0%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F%20%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0%20%D0%B8%20%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B5%20%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D0%B8',
    }
  ];

  const highlightsMaster = [
    {
      title: '01.04.02 Прикладная математика и информатика',
      description: '<p class="p_description"><span class="d_description">Кол-во бюджетных мест: </span><span class="v_description">40</span></p>' +
          '<p class="p_description"><span class="d_description">Кол-во платных мест: </span><span class="v_description">10+</span></p>' +
          '<p class="p_description"><span class="d_description">Форма обучения: </span><span class="dv_description">Очная</span></p>',
      link: 'https://priem.mai.ru/master/programs/?mega=&mega=Компьютерные+науки+и+прикладная+математика',
    },
    {
      title: '01.04.04 Прикладная математика',
      description: '<p class="p_description"><span class="d_description">Кол-во бюджетных мест: </span><span class="v_description">50</span></p>' +
          '<p class="p_description"><span class="d_description">Кол-во платных мест: </span><span class="v_description">10+</span></p>' +
          '<p class="p_description"><span class="d_description">Форма обучения: </span><span class="dv_description">Очная</span></p>',
      link: 'https://priem.mai.ru/master/programs/?mega=&mega=Компьютерные+науки+и+прикладная+математика',
    },
    {
      title: '02.04.02 Фундаментальная информатика и информационные технологии',
      description: '<p class="p_description"><span class="d_description">Кол-во бюджетных мест: </span><span class="v_description">65</span></p>' +
          '<p class="p_description"><span class="d_description">Кол-во платных мест: </span><span class="v_description">10+</span></p>' +
          '<p class="p_description"><span class="d_description">Форма обучения:</span><span class="dv_description">Очная</span></p>',
      link: 'https://priem.mai.ru/master/programs/?mega=&mega=Компьютерные+науки+и+прикладная+математика',
    },
    {
      title: '<span class="sber-title">Онлайн-магистратура «Машинное обучение и анализ данных»</span>',
      description: '<p class="p_description"><span class="d_description">Онлайн-магистратура от МАИ и Сбера "Машинное обучение и анализ данных".</span></p>' +
          '<p class="p_description"><span class="d_description">Кол-во платных мест: </span><span class="v_description">40+</span></p>' +
          '<p class="p_description"><span class="d_description">Форма обучения: </span><span class="dv_description">Очная (Онлайн)</span></p>',
      link: 'https://academy.edutoria.ru/2023-mag/mai-machine-learning/',
    },
  ];

  const faqs = [{
    question: '8 > 3', answer: 'True',
  }, {
    question: 'Кем становятся выпускники после обучения на программах Института №8?',
    answer: 'Студенты и выпускники работают в различных сферах. Самые частые профессии:<ul>\n' + '  <li>\n' + '    <p>Программисты и разработчики: веб, мобильные приложения, программное обеспечение, игры;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Системные аналитики: анализ бизнес-потребностей, оптимизация рабочих процессов;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Инженеры по тестированию ПО: тестирование, обеспечение качества;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Сетевые и системные администраторы: управление сетями, безопасность;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Аналитики данных: анализ больших данных, создание инсайтов;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Инженеры и архитекторы искусственного интеллекта: разработка решений ИИ, машинное обучение;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Консультанты по IT: оптимизация технических процессов, бизнес-потоки;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Преподаватели и исследователи: наука, университеты, исследовательские лаборатории;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Криптографы и специалисты по кибербезопасности: защита информации, криптографические системы.</p>\n' + '  </li>\n' + '</ul>\n',
  }, {
    question: 'Как получить общежитие?',
    answer: 'Общежитие предоставляется иногородним студентам, не проживающим в Москве или ближайшем Подмосковье, на основе конкурса. Количество мест в общежитии составляет примерно 25% от общего числа бюджетных мест.',
  }, {
    question: 'Есть ли военная кафедра?',
    answer: 'Да, в нашем университете есть возможность прохождения обучения на военной кафедре. Для этого необходимо подать заявление на участие в конкурсе на втором семестре обучения. Прохождение на военную кафедру предполагает сдачу нормативов по физической подготовке (бег на 3 км, бег на 100 м, подтягивания) и прохождение медицинского осмотра в военкомате. Обучение на военной кафедре начинается с второго курса и проходит один раз в неделю. Продолжительность обучения составляет 3 года. По окончании обучения студентам присваивается воинское звание \'Лейтенант запаса\'.',
  }, {
    question: 'Как можно подать документы на поступление?',
    answer: 'Документы в ВУЗ можно подать любым из следующих способов:' + '<ul>\n' + '  <li>\n' + '    <p>Лично, в приёмной комиссии в вузе</p>\n' + '  </li>\n' + '  </li>\n' + '  <li>\n' + '    <p>Суперсервис "Поступление в вуз онлайн"</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Через операторов почтовой связи</p>\n' + '  </li>\n' + '</ul>'
    // + 'Узнать подробнее про поступление и подать документы можно <a href="/admission/application" style="color: #007acd" target="_blank" rel="noopener noreferrer">тут</a>.',
  }, {
    question: 'Какие есть дополнительные возможности для развития?',
    answer: 'Институт №8 МАИ предлагает студентам разнообразные возможности развития: Авиахакатон с задачами от крупных компаний, Весенняя школа математического моделирования и IT с лекциями от специалистов, а также дни IT компаний с лекциями и информацией о стажировках.<br/>Все это способствует развитию навыков, погружению в современные технологии и установлению связей с ведущими компаниями.'
  }, {
    question: 'Какие условия и стоимость платного обучения?',
    answer: 'Обучение на платной основе возможно при условии предоставления документов в приемную комиссию до 14 августа. Согласие о зачислении необходимо подать до 16 августа. После заключения договора обязательно оплатить первый семестр обучения до 22 августа. Стоимость обучения составляет 248 170 рублей в год.<br/><br/>Информация актуальна на 2023 год!'
  }];

  const [logoData, setLogoData] = useState(null);
  const [carouselItems, setCarouselItems] = useState(null);
  const [companyLogoData, setCompanyLogoData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventResponse = await axios.get('https://itmai.ru/storage/event2.json');
        // setPopupData(eventResponse.data);

        const logoResponse = await axios.get('https://itmai.ru/storage/stacklogos.json');
        setLogoData(logoResponse.data);

        const carouselResponse = await axios.get('https://itmai.ru/storage/carousel.json');
        setCarouselItems(carouselResponse.data);

        const companyLogoResponse = await axios.get('https://itmai.ru/storage/komplogos.json');
        setCompanyLogoData(companyLogoResponse.data);
      } catch (error) {

      }
    };

    fetchData();
  }, []);

  const description = "Институт №8 «Компьютерные науки и прикладная математика» — признанная в России и за рубежом научная математическая и IT-школа. Уже более полувека мы готовим талантливых профессионалов, которые успешно применяют свои знания в различных областях, включая IT, финансы, робототехнику, экономику, авиацию, космонавтику и другие востребованные направления.";
  const keywords = "Институт №8, Компьютерные науки, Прикладная математика, IT-школа, профессионалы, образование, IT, финансы, робототехника, экономика, авиация, космонавтика, научные исследования, преподаватели, высокотехнологичные компании, студенты, научные мероприятия, карьера";

  const OPTIONS = {loop: true}
  var SLIDES = [];
  for (let i = 0; i < 11; i++) {
    SLIDES.push('../../assets/images/slider/' + i + '.jpg')
  }

  const [isOpen, setIsOpen] = useState(false);
  const [isWelcomePopupOpen, setIsWelcomePopupOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleWelcomePopupClose = () => {
    setIsWelcomePopupOpen(false);
  };

  const [iframeHeight, setIframeHeight] = useState('650px'); // Установите начальную высоту iframe
  const iframeRef = useRef(null);

  useEffect(() => {
    const resizeIframe = () => {
      if (iframeRef.current) {
        setIframeHeight(`${iframeRef.current.contentWindow.document.body.scrollHeight}px`);
      }
    };

    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', resizeIframe);
      resizeIframe(); // Вызовите функцию один раз после монтирования компонента, чтобы установить правильную высоту сразу
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', resizeIframe);
      }
    };
  }, []);

  return (
      <div className='container'>
        <Helmet>
          <meta name="description" content={description}/>
          <meta name="keywords" content={keywords}/>
        </Helmet>

        <Popup isOpen={isOpen} onClose={handleClose} maxWidth="600px">
          <h2 style={{fontSize: '2rem', color: '#90d3f9'}}>
            Регистрация на день открытых дверей
          </h2>
          <div style={{display: "flex", justifyContent: "center"}}>
            <div>
              <iframe ref={iframeRef} src="https://forms.yandex.ru/cloud/65ec9baf068ff06a6f6baa8c/?iframe=1"
                      frameBorder="0"
                      name="ya-form-65ec9baf068ff06a6f6baa8c"
                      style={{width: '100%', height: iframeHeight, maxHeight: '600px', overflow: "hidden"}}
              ></iframe>
            </div>
          </div>
        </Popup>

        <Popup isOpen={isWelcomePopupOpen} onClose={handleWelcomePopupClose} maxWidth="600px">
          <h2 style={{fontSize: '2rem', color: '#90d3f9'}}>
            Привет, абитуриент!
          </h2>
          <div style={{}}>
            <p style={{fontSize: '1.3rem', lineHeight: 1.3, fontWeight: 400}}>
              Присоединяйся к нашему телеграм-чату для абитуриентов 2024 года! В нем ты сможешь получать оперативную
              информацию от приемной комиссии и задавать любые вопросы директору Института №8, Сергею Сергеевичу
              Крылову.
              <br/>
              <div style={{marginTop: '1rem'}}>
                <a href='https://t.me/pk8mai' target='_blank' rel='noopener noreferrer'
                   style={{color: '#90d3f9', textDecoration: 'none'}}>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', fontSize: '1.5rem'}}>
                    Присоединиться к чату <ArrowRight style={{marginLeft: '5px'}} size={30}/>
                  </div>
                </a>
              </div>
            </p>
          </div>
        </Popup>

        <div className='cool-header'>
          <h1>
          <span aria-label="Институт №8 — это IT">
            Институт №8 МАИ —&nbsp;это&nbsp;<span className="typewriter thick"></span>
          </span>
          </h1>
        </div>
        {/*<div className='block-dod-slogan-container'>*/}
        {/*  <span className='block-dod-slogan'>если все мысли об <span className='block-dod-slogan-accent'>IT</span> - поступай в <span*/}
        {/*      className='block-dod-slogan-accent'>МАИ</span>*/}
        {/*  </span>*/}
        {/*  <span className='block-dod-slogan'>если все мысли об <span className='block-dod-slogan-accent'>IT</span> - поступай в <span*/}
        {/*      className='block-dod-slogan-accent'>МАИ</span>*/}
        {/*  </span>*/}
        {/*  <span className='block-dod-slogan'>если все мысли об <span className='block-dod-slogan-accent'>IT</span> - поступай в <span*/}
        {/*      className='block-dod-slogan-accent'>МАИ</span>*/}
        {/*  </span>*/}
        {/*  <span className='block-dod-slogan'>если все мысли об <span className='block-dod-slogan-accent'>IT</span> - поступай в <span*/}
        {/*      className='block-dod-slogan-accent'>МАИ</span>*/}
        {/*  </span>*/}
        {/*</div>*/}
        {/*<div className='block-dod'>*/}
        {/*  <div className="marquee_wrapper" style={{paddingTop: '1rem'}}>*/}
        {/*    <div className="marquee_items">*/}
        {/*      <div className="">*/}
        {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
        {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
        {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
        {/*      </span>*/}
        {/*      </div>*/}
        {/*      <div className="">*/}
        {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
        {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
        {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
        {/*      </span>*/}
        {/*      </div>*/}
        {/*      <div className="">*/}
        {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
        {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
        {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
        {/*      </span>*/}
        {/*      </div>*/}
        {/*      <div className="">*/}
        {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
        {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
        {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
        {/*      </span>*/}
        {/*      </div>*/}
        {/*      <div className="">*/}
        {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
        {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
        {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
        {/*      </span>*/}
        {/*      </div>*/}
        {/*      <div className="">*/}
        {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
        {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
        {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
        {/*      </span>*/}
        {/*      </div>*/}
        {/*      <div className="">*/}
        {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
        {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
        {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
        {/*      </span>*/}
        {/*      </div>*/}
        {/*      <div className="">*/}
        {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
        {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
        {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
        {/*      </span>*/}
        {/*      </div>*/}
        {/*      <div className="">*/}
        {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
        {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
        {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
        {/*      </span>*/}
        {/*      </div>*/}
        {/*      <div className="">*/}
        {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
        {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
        {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
        {/*      </span>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className='block-dod-main' style={{*/}
        {/*    paddingLeft: '50px',*/}
        {/*    paddingBottom: '50px',*/}
        {/*    paddingTop: '25px',*/}
        {/*    display: 'flex',*/}
        {/*    justifyContent: 'space-between',*/}
        {/*    flexDirection: 'column',*/}
        {/*    flex: 1*/}
        {/*  }}>*/}
        {/*    <div className='block-dod-date'>27 апреля 2024</div>*/}
        {/*    <div style={{*/}
        {/*      display: 'flex',*/}
        {/*      flexDirection: 'row',*/}
        {/*      gap: '20px',*/}
        {/*      marginTop: '10px',*/}
        {/*      marginBottom: '10px',*/}
        {/*    }}>*/}
        {/*      <div className='block-dod-logo--main'>16:00 - 17:00</div>*/}
        {/*      <div className='block-dod-logo--main'>онлайн</div>*/}
        {/*    </div>*/}
        {/*    <div className='block-dod-name'>День открытых дверей</div>*/}
        {/*    <div className='block-dod-inst'>Институт №8 "Компьютерные науки и прикладная математика"</div>*/}
        {/*    <button className='block-dod-logo button' onClick={() => {*/}
        {/*      setIsOpen(true);*/}
        {/*    }}>Зарегистрироваться*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className="content">
          <section id='live-dod'>
            <h2>День открытых дверей</h2>
            <p className="section-text">
              Задавай вопросы в <a style={{color: '#C6D7DA', textDecoration: "underline"}} target="_blank" rel="noopener noreferrer" href='https://t.me/pk8mai'>нашем телеграм чате</a> или в чате на <a style={{color: '#c5c5c5', textDecoration: "underline", fontSize: '1.5rem'}} target="_blank" rel="noopener noreferrer" href='https://vk.com/video-153639485_456239449'>платформе VK Video</a>.

            </p>
            <div style={{paddingLeft: '2%', paddingRight: '2%'}}>
              <div style={{position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden'}}>
                <iframe
                    src='https://vk.com/video_ext.php?oid=-153639485&id=456239449&autoplay=1'
                    style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                    allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
              </div>
            </div>
            <p className="section-text">
              Хочешь заглянуть в будущее и узнать каким программистом ты станешь, поступив в МАИ? Тогда обязательно
              подключайся к Дню открытых дверей института №8 “Компьютерные науки и прикладная математика”, который
              пройдет в онлайн (дистанционном) формате!
            </p>
            <p className="section-text">
              На мероприятии ты узнаешь:
              <ul>
                <li>Как и кем в институте реализуются современные направления: ML, DS, Full stack разработка,
                  алгоритмы и структуры данных.
                </li>
                <li>Про карьерные траектории выпускников.</li>
                <li>Олимпиадное программирование в институте №8.</li>
                <li>Предварительный прием документов и условия поступления.</li>
              </ul>
              А также про общагу и многое другое!
            </p>
            <p className="section-text">
              Если ты все ещё задаешься вопросом зачем программисту нужна фундаментальная математика или зачем soft
              skills, то ждем тебя.
            </p>
          </section>
          {/*<div className="highlights-container">*/}
          {/*  <div*/}
          {/*      style={{width: '550px'}}*/}
          {/*      className="highlight"*/}
          {/*      onClick={() => window.location.href = '/priem'}*/}
          {/*  >*/}
          {/*    <div className="button_sec">*/}
          {/*      Приемная комиссия*/}
          {/*    </div>*/}
          {/*  </div>*/}

          {/*  <div*/}
          {/*      style={{width: '550px'}}*/}
          {/*      className="highlight"*/}
          {/*      onClick={() => {*/}
          {/*        const contactsElement = document.getElementById('contacts');*/}
          {/*        contactsElement.scrollIntoView({behavior: 'smooth'});*/}
          {/*      }}*/}
          {/*  >*/}
          {/*    <div className="button_sec">*/}
          {/*      Связаться с нами*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <section id='institute-specialization'>
            <h2>Направления подготовки</h2>
            <section id='bvo-spec'>
              <h3>Базовое высшее образование (4 года)</h3>
              <Highlight data={highlightsBachelor} highlightWidth="550px"/>
            </section>
            <section id='svo-spec'>
              <h3>Специализированное высшее образование (2 года)</h3>
              <Highlight data={highlightsMaster} highlightWidth="550px"/>
            </section>
          </section>
          <section id='technology'>
            <h2>Стек технологий выпускника</h2>
            {logoData ? (<Marquee logos={logoData.stacklogos}/>) : (<p>Загрузка...</p>)}
          </section>
          {/*<section id='partners'>*/}
          {/*  <h2>Наши партнеры</h2>*/}
          {/*  {companyLogoData ? (<Marquee logos={companyLogoData.komplogos}/>) : (<p>Загрузка...</p>)}*/}
          {/*</section>*/}
          <section id="about-institute">
            <h2>О нас</h2>
            <AnimatedNumberComponent id={1} value={163} duration={3000} description={'преподавателя'}/>
            <AnimatedNumberComponent id={2} value={20} duration={3000} description={'компаний-партнеров'}/>
            <AnimatedNumberComponent id={3} value={6} duration={3000}
                                     description={'место по популярности по версии Табитуриент'}/>
            <AnimatedNumberComponent id={4} value={1} duration={3000} description={'робособака Дора'}/>
            <AnimatedNumberComponent id={5} value={1023} duration={3000}
                                     description={'квадратных метра IT-пространства'}/>
            <div className="marquee_wrapper">
              <div className="marquee_items">
                <div className="marquee_item">дни it-компаний</div>
                <div className="marquee_item">робособака Дора</div>
                <div className="marquee_item">380 терафлопс супервычислителя</div>
                <div className="marquee_item">it-пространство</div>
                <div className="marquee_item">авиахакатон</div>
                <div className="marquee_item">школа матмода</div>
                <div className="marquee_item">it-проекты</div>
                <div className="marquee_item">технологичные лаборатории</div>
                <div className="marquee_item">преподаватели-практики</div>
                <div className="marquee_item">практический опыт</div>
                <div className="marquee_item">современные технологии</div>

                <div className="marquee_item">дни it-компаний</div>
                <div className="marquee_item">робособака Дора</div>
                <div className="marquee_item">380 терафлопс супервычислителя</div>
                <div className="marquee_item">it-пространство</div>
                <div className="marquee_item">авиахакатон</div>
                <div className="marquee_item">школа матмода</div>
                <div className="marquee_item">it-проекты</div>
                <div className="marquee_item">технологичные лаборатории</div>
                <div className="marquee_item">преподаватели-практики</div>
                <div className="marquee_item">практический опыт</div>
                <div className="marquee_item">современные технологии</div>
              </div>
            </div>

            <p className="section-text">
              Добро пожаловать в Институт №8 "Компьютерные науки и прикладная математика"! Мы являемся ведущей
              научной,
              математической и IT-школой, признанной в России и за ее пределами. Наши студенты и выпускники успешно
              применяют полученные знания в различных сферах, включая IT, финансы, робототехнику, экономику, авиацию и
              космонавтику.
            </p>
            <p className="section-text">
              Особенность нашего подхода заключается в активном участии IT-индустрии и науки в образовательном
              процессе.
              Это позволяет студентам развивать навыки в исследованиях, глубже погружаться в предмет и делиться
              знаниями
              с
              коллегами. Наши студенты уже на этапе обучения получают опыт работы в крупнейших компаниях: Сбер,
              Тинькофф,
              МТС, Яндекс, VK, Лаборатория Касперского и других лидерах рынка. Этот опыт дополняет образование и
              создает
              прочную основу для успешной карьеры после завершения обучения.
            </p>
          </section>
          {carouselItems ? (<EmblaCarousel slides={carouselItems.images} options={OPTIONS}/>) : (<p>Загрузка...</p>)}

          <section id='benifits'>
            <h2>Преимущества обучения</h2>
            <Highlight data={benefits} highlightWidth="550px"/>
          </section>
          <section id='faqs'>
            <h2>Частые вопросы</h2>
            <FAQSection faqs={faqs}/>
          </section>
        </div>
      </div>
  );
};

export default HomePage;