import React from 'react';
import './HighLight.css';
import {ArrowRight} from 'lucide-react';


const Highlight = ({data, highlightWidth}) => {
  const getTileCount = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 480) {
      return 1; // Show 1 tile per row on screens up to 480px wide
    } else {
      return 2;
    }
  };

  const tileCount = getTileCount();

  const handleHighlightClick = (link) => {
    if (link) {
      window.location.href = link;
    }
  };

  return (
      <div className="highlights-container">
        {data.map(({title, description, link}, index) => {

          const highlightStyle = {
            width: highlightWidth, // Set the width style based on the highlightWidth prop
          };

          return (
              <div
                  key={index}
                  className={`highlight ${
                      tileCount === 1 ? 'single' : 'double'
                  }`}
                  // onClick={() => handleHighlightClick(link)}
                  style={highlightStyle}
              >
                <div className="highlight-content-h">
                  <div className='content-wrapper'>
                    <p className="highlight-title" dangerouslySetInnerHTML={{__html: title}}></p>
                    <p className="highlight-description" dangerouslySetInnerHTML={{__html: description}}></p>
                  </div>
                  {link && <div className='link-wrapper'>
                    <a href={link} target='_blank' rel='noopener noreferrer'>
                      <div className='highlight-link-container'>
                        Подробности <ArrowRight style={{marginLeft: '5px'}} size={20}/></div>
                    </a>
                  </div>}
                </div>
              </div>
          );
        })}
      </div>
  );
};

export default Highlight;