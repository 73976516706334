import React, {useCallback, useEffect, useState} from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import {NextButton, PrevButton, usePrevNextButtons} from './EmblaCarouselArrowButtons'
import './embla.css'

const EmblaCarousel = (props) => {
  const {slides, options} = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay({delay: 3000, jump: true})
  ])
  const [isPlaying, setIsPlaying] = useState(false)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  const onButtonAutoplayClick = useCallback(
      (callback) => {
        const autoplay = emblaApi?.plugins()?.autoplay
        if (!autoplay) return

        const resetOrStop =
            autoplay.options.stopOnInteraction === false
                ? autoplay.reset
                : autoplay.stop

        resetOrStop()
        callback()
      },
      [emblaApi]
  )

  const toggleAutoplay = useCallback(() => {
    const autoplay = emblaApi?.plugins()?.autoplay
    if (!autoplay) return

    const playOrStop = autoplay.isPlaying() ? autoplay.stop : autoplay.play
    playOrStop()
  }, [emblaApi])

  useEffect(() => {
    const autoplay = emblaApi?.plugins()?.autoplay
    if (!autoplay) return

    setIsPlaying(autoplay.isPlaying())
    emblaApi
        .on('autoplay:play', () => setIsPlaying(true))
        .on('autoplay:stop', () => setIsPlaying(false))
        .on('reInit', () => setIsPlaying(false))
  }, [emblaApi])

  return (
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {slides.map((image) => (
                <div className="embla__slide">
                  <div className="container-wrapper">
                    <div className="image-container">
                      <img className="responsive-image" src={image} alt={image}/>
                    </div>
                  </div>
                </div>
            ))}
          </div>
        </div>

        <div className="embla__controls">
          <div className="embla__buttons">
            <PrevButton
                onClick={() => onButtonAutoplayClick(onPrevButtonClick)}
                disabled={prevBtnDisabled}
            />
            <NextButton
                onClick={() => onButtonAutoplayClick(onNextButtonClick)}
                disabled={nextBtnDisabled}
            />
          </div>
        </div>
      </div>
)
}

export default EmblaCarousel