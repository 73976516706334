import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './FAQSection.css';
import { useInView } from "react-intersection-observer";
import { ChevronDown } from 'lucide-react';

const FAQSection = ({ faqs }) => {
  const [selectedFaq, setSelectedFaq] = useState([]);

  const endAnimationArrow = (arrow) => {
    console.log('animate end')
    arrow.classList.remove('rotateTo')
    arrow.classList.remove('rotateFrom')
  }

  const handleFaqClick = (index) => {
    if (selectedFaq.indexOf(index) === -1) {
      setSelectedFaq(
        [...selectedFaq, index]
      );
    } else {
      setSelectedFaq(
        selectedFaq.filter(a =>
          a !== index
        )
      );
    }

    const arrow = document.getElementById('faq-arrow-' + index);
    const answer = document.getElementById('faq-answer-' + index);
    if (arrow.classList.contains('rotateTo')) {
      arrow.addEventListener("animationend", () => {
        endAnimationArrow(arrow)
      }, { once: true });
      answer.classList.toggle('is_open')
      arrow.classList.add('rotateFrom')
    } else {
      answer.classList.toggle('is_open')
      arrow.classList.add('rotateTo')
    }

  };

  useEffect(() => {
    console.log(selectedFaq)

  }, [selectedFaq])


  return (
    <div className="faq-section">
      {faqs.map((faq, index) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [ref, inView] = useInView({
          triggerOnce: true,
          rootMargin: '-20px 0px', // Регулируйте этот отступ по своему усмотрению
        });

        const faqVariants = {
          hidden: { opacity: 0, y: -10 },
          visible: { opacity: 1, y: 0 },
        };

        return (
          <div
            className="faq-item"
            key={index}
            ref={ref}
          >
            <div
              className={`faq-question  ${selectedFaq.indexOf(index) != -1 ? 'active' : ''}`}
              onClick={() => handleFaqClick(index)}
            >
              <>
              {faq.question}
              </>

              <div className="faq-arrow" id={`faq-arrow-${index}`}>
                <ChevronDown size={20} strokeWidth={2.25} />
              </div>
            </div>
            <div className='faq-answer' id={`faq-answer-${index}`}>
              <p dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </div>
            {false && selectedFaq.indexOf(index) !== -1 && (
              <div className='faq-answer' id={`faq-answer-${index}`}>
                <p dangerouslySetInnerHTML={{ __html: faq.answer }} />
              </div>
              // <p className="faq-answer" dangerouslySetInnerHTML={{ __html: faq.answer }} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FAQSection;